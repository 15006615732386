import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-y-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_share = _resolveComponent("share")!
  const _component_Label = _resolveComponent("Label")!
  const _component_total = _resolveComponent("total")!
  const _component_create_checkpoint = _resolveComponent("create-checkpoint")!
  const _component_list_checkpoints = _resolveComponent("list-checkpoints")!
  const _component_create_task = _resolveComponent("create-task")!
  const _component_list_tasks = _resolveComponent("list-tasks")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Title, null, {
      default: _withCtx(() => [
        _createTextVNode("SHARE")
      ]),
      _: 1
    }),
    _createVNode(_component_share),
    _createVNode(_component_Title, null, {
      default: _withCtx(() => [
        _createTextVNode("INFOS")
      ]),
      _: 1
    }),
    _createVNode(_component_Label, { edit: true }),
    _createVNode(_component_Title, null, {
      default: _withCtx(() => [
        _createTextVNode("CHECKPOINTS")
      ]),
      _: 1
    }),
    _createVNode(_component_total, { edit: true }),
    _createVNode(_component_create_checkpoint),
    _createVNode(_component_list_checkpoints, { edit: true }),
    _createVNode(_component_Title, null, {
      default: _withCtx(() => [
        _createTextVNode("TASKS")
      ]),
      _: 1
    }),
    _createVNode(_component_create_task),
    _createVNode(_component_list_tasks, { edit: true })
  ]))
}