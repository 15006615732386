import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, { class: "flex justify-between" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: "Task label",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.label) = $event))
      }, null, 512), [
        [_vModelText, _ctx.label]
      ]),
      _createVNode(_component_Button, { onClick: _ctx.create }, {
        default: _withCtx(() => [
          _createTextVNode("SEND")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}