import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _createTextVNode(" PRIVATE URL: "),
          _createElementVNode("a", {
            href: _ctx.url + '/' + _ctx.publicId + '/' + _ctx.privateId
          }, _toDisplayString(_ctx.url) + "/" + _toDisplayString(_ctx.publicId) + "/" + _toDisplayString(_ctx.privateId), 9, _hoisted_1)
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _createTextVNode(" PUBLIC URL: "),
          _createElementVNode("a", {
            href: _ctx.url + '/' + _ctx.publicId
          }, _toDisplayString(_ctx.url) + "/" + _toDisplayString(_ctx.publicId), 9, _hoisted_2)
        ])
      ])
    ]),
    _: 1
  }))
}